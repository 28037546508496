<template>
  <el-dialog :lock-scroll="true" :top="'50px'" v-model="show" title="Teste">

    <template v-slot:title>
      <div style="border-bottom: #e0e0e0 1px solid;padding: 20px;">
        <div class="modal-title" style="display: flex;width: calc(100% - 50px)">

          <el-input v-model="query" :placeholder="KT(search)"
            style="--el-input-border-radius: 5px;margin-right: 5px;"></el-input>

        </div>
      </div>
    </template>
    <template v-slot:footer>

    </template>
    <div class="itm" style="display: flex;background: #eeeeee;">
      <!-- Switch Global para seleccionar todos -->
      <div style="width: 50px;padding: 10px;font-size: 12px;">
        <el-switch v-model="selectAll" @change="handleSelectAllChange" :size="'large'"></el-switch>
      </div>
      <div style="width: 30px;text-align: center;padding: 10px;font-size: 12px;">Id</div>
      <div style="flex: 1;padding: 10px;font-size: 12px;text-align: center;">Nome</div>
    </div>
    <div style="height: calc(100vh - 300px); overflow: hidden; overflow-y: scroll;">

      <div class="itm" v-for="(u, k) in filteredObjects" :key="k" :class="{ tr1: (k % 2), tr2: !(k % 2) }"
        style="display: flex;">

        <div style="width: 50px;padding: 10px;font-size: 14px;">
          <el-switch :model-value="isLinked(u.id)" @change="changeLink(u, $event)" :size="'large'"></el-switch>
        </div>
        <div style="width: 30px;text-align: center;padding: 10px;font-size: 14px;">
          {{ u.id }}
        </div>
        <template v-if="objectType === 'notifications' && !u.attributes['description']">
          <div style="flex: 1;padding: 10px;font-size: 14px;text-align: center;">{{ KT('notification.types.' + u.type) }}
          </div>
          <div style="flex: 1;padding: 10px;font-size: 14px;text-align: center;">
            <template v-if="u.notificators.split(',').length">
              <span class="tblItem" v-for="(a, b) in u.notificators.split(',')"
                :key="b">{{ KT('notification.channels.' + a, a) }}</span>
            </template>
          </div>
          <div style="flex: 1;padding: 10px;font-size: 14px;text-align: center;">
            <template v-if="u.attributes['alarms']">
              <span class="tblItem" v-for="(a, b) in u.attributes['alarms'].split(',')"
                :key="b">{{ KT('alarms.' + a, a) }}</span>
            </template>
          </div>
        </template>
        <div v-else-if="objectType === 'devices'" style="flex: 1;padding: 10px;font-size: 14px;text-align: center;">
          {{ u.name ||
            u.description || u.attributes['description']}} | {{ u.attributes['placa'] || u.uniqueId }}</div>
        <div v-else style="flex: 1;padding: 10px;font-size: 14px;text-align: center;">{{ u.name || u.description ||
          u.attributes['description']}}</div>
      </div>

    </div>

  </el-dialog>
</template>

<script setup>
  import { ref, defineExpose, provide, computed } from 'vue';
  import 'element-plus/es/components/input/style/css';
  import 'element-plus/es/components/button/style/css';
  import 'element-plus/es/components/switch/style/css';
  import 'element-plus/es/components/dialog/style/css';

  import { ElDialog, ElSwitch, ElInput, ElNotification } from "element-plus";

  const query = ref('');
  const show = ref(false);
  const search = ref('search');

  const objectType = ref(null);
  const selection = ref({});
  const keyType = ref(null);
  const availableObjects = ref([]);
  const selectedObjects = ref([]);

  import KT from '../../func/kt';

  const isLinked = (id) => {
    return selectedObjects.value.some((f) => f.id === id);
  };

  const changeLink = async (obj, state) => {
    let tmp = JSON.parse(JSON.stringify(selection.value));
    tmp[keyType.value] = obj.id;
    const userName = 'Usuario Ejemplo';  // Deberías obtener el nombre del usuario actual de tu sistema
    const userId = 123;  // Deberías obtener el ID del usuario actual de tu sistema

    let notificationMessage = '';

    if (state) {
      selectedObjects.value.push(obj);
      await window.$traccar.linkObjects(tmp);
      notificationMessage = `${obj.name} ha sido asignado al usuario ${userName} (ID: ${userId})`;
    } else {
      selectedObjects.value = selectedObjects.value.filter(f => f.id !== obj.id);
      await window.$traccar.unlinkObjects(tmp);
      notificationMessage = `${obj.name} ha sido desvinculado del usuario ${userName} (ID: ${userId})`;
    }

    // Mostrar la notificación flotante
    ElNotification({
      title: 'Proceso completado',
      message: notificationMessage,
      type: 'success',
      duration: 5000,  // Duración de la notificación
      position: 'top-right',  // Posición flotante
    });

    // Actualizar el estado de selectAll después de cada cambio
    updateSelectAllState();
  };
  // Estado para el Switch Global "Seleccionar Todos"
  const selectAll = ref(false);

  // Cambiar todos los interruptores según el valor de "selectAll"
  const handleSelectAllChange = async (state) => {
    // Flag para saber si todos los cambios se han completado exitosamente
    let success = true;

    // Iterar sobre los objetos de forma secuencial
    for (const obj of filteredObjects.value) {
      if (state) {
        if (!isLinked(obj.id)) {
          await changeLink(obj, true);
        }
      } else {
        if (isLinked(obj.id)) {
          await changeLink(obj, false);
        }
      }
    }

    // Después de aplicar la selección global, actualizar el valor de `selectAll`
    updateSelectAllState();

    // Mostrar notificación de éxito final
    if (success) {
      ElNotification({
        title: 'Processo Finalizado',  // Cambié el título a "Êxito"
        message: 'Todos os objetos foram processados com sucesso!', // Mensaje en portugués
        type: 'success',
        duration: 10000,
        position: 'top-right',
      });
    }
  };


  // Actualiza el estado de `selectAll` cuando se cambian los interruptores individuales
  const updateSelectAllState = () => {
    // Verificar si todos los objetos del tipo seleccionado están marcados
    selectAll.value = filteredObjects.value.every((obj) => isLinked(obj.id));
  };

  // Filtrar objetos según la búsqueda
  const filteredObjects = computed(() => {
    if (query.value.length < 3) {
      return availableObjects.value;
    } else {
      return availableObjects.value.filter((f) => {
        for (let k of Object.keys(f)) {
          if (String(f[k]).toLowerCase().match(query.value.toLowerCase())) {
            return true;
          }
        }
        for (let k of Object.keys(f.attributes)) {
          if (String(f.attributes[k]).toLowerCase().match(query.value.toLowerCase())) {
            return true;
          }
        }
        return false;
      });
    }
  });

  const showObjects = (params) => {
    selection.value = {};

    objectType.value = params.type;
    selection.value[Object.keys(params)[0]] = params[Object.keys(params)[0]];

    if (params.type === 'geofences') {
      keyType.value = 'geofenceId';
      search.value = 'geofence.search';

      window.$traccar.getGeofences({ all: true }).then(({ data }) => {
        availableObjects.value = data;
        // Verificar si todos los elementos están seleccionados
        updateSelectAllState();  // Actualizar el estado del 'selectAll'
      });

      window.$traccar.getGeofences(selection.value).then(({ data }) => {
        selectedObjects.value = data;
      });
    } else if (params.type === 'devices') {
      keyType.value = 'deviceId';
      search.value = 'device.search';

      window.$traccar.getDevices({ all: true }).then(({ data }) => {
        let tmp = [];
        data.forEach((d) => {
          if (!(d.uniqueId.split("-").length === 3 && d.uniqueId.split("-")[0] === "deleted")) {
            tmp.push(d);
          }
        });
        availableObjects.value = tmp;
        // Verificar si todos los elementos están seleccionados
        updateSelectAllState();  // Actualizar el estado del 'selectAll'
      });

      window.$traccar.getDevices(selection.value).then(({ data }) => {
        selectedObjects.value = data;
      });
    } else if (params.type === 'commands') {
      keyType.value = 'commandId';
      search.value = 'command.search';

      window.$traccar.getSavedCommands({ all: true }).then(({ data }) => {
        availableObjects.value = data;
        // Verificar si todos los elementos están seleccionados
        updateSelectAllState();  // Actualizar el estado del 'selectAll'
      });

      window.$traccar.getSavedCommands(selection.value).then(({ data }) => {
        selectedObjects.value = data;
      });
    } else if (params.type === 'maintence') {
      keyType.value = 'maintenanceId';
      search.value = 'maintenance.search';

      window.$traccar.getMaintenance({ all: true }).then(({ data }) => {
        availableObjects.value = data;
        // Verificar si todos los elementos están seleccionados
        updateSelectAllState();  // Actualizar el estado del 'selectAll'
      });

      window.$traccar.getMaintenance(selection.value).then(({ data }) => {
        selectedObjects.value = data;
      });
    } else if (params.type === 'attributes') {
      keyType.value = 'attributeId';
      search.value = 'attribute.search';

      window.$traccar.getComputedAttributes({ all: true }).then(({ data }) => {
        availableObjects.value = data;
        // Verificar si todos los elementos están seleccionados
        updateSelectAllState();  // Actualizar el estado del 'selectAll'
      });

      window.$traccar.getComputedAttributes(selection.value).then(({ data }) => {
        selectedObjects.value = data;
      });
    } else if (params.type === 'calendars') {
      keyType.value = 'calendarId';
      search.value = 'calendar.search';

      window.$traccar.getCalendars({ all: true }).then(({ data }) => {
        availableObjects.value = data;
        // Verificar si todos los elementos están seleccionados
        updateSelectAllState();  // Actualizar el estado del 'selectAll'
      });

      window.$traccar.getCalendars(selection.value).then(({ data }) => {
        selectedObjects.value = data;
      });
    } else if (params.type === 'notifications') {
      keyType.value = 'notificationId';
      search.value = 'notification.search';

      window.$traccar.getNotifications({ all: true }).then(({ data }) => {
        availableObjects.value = data;
        // Verificar si todos los elementos están seleccionados
        updateSelectAllState();  // Actualizar el estado del 'selectAll'
      });

      window.$traccar.getNotifications(selection.value).then(({ data }) => {
        selectedObjects.value = data;
      });
    } else if (params.type === 'users') {
      keyType.value = 'managedUserId';
      search.value = 'user.search';

      window.$traccar.getUsers({ all: true }).then(({ data }) => {
        availableObjects.value = data;
        // Verificar si todos los elementos están seleccionados
        updateSelectAllState();  // Actualizar el estado del 'selectAll'
      });

      window.$traccar.getUsers(selection.value).then(({ data }) => {
        selectedObjects.value = data;
      });
    } else if (params.type === 'groups') {
      keyType.value = 'groupId';
      search.value = 'group.search';

      window.$traccar.getGroups({ all: true }).then(({ data }) => {
        availableObjects.value = data;
        // Verificar si todos los elementos están seleccionados
        updateSelectAllState();  // Actualizar el estado del 'selectAll'
      });

      window.$traccar.getGroups(selection.value).then(({ data }) => {
        selectedObjects.value = data;
      });
    } else if (params.type === 'drivers') {
      keyType.value = 'driverId';
      search.value = 'driver.search';

      window.$traccar.getDrivers({ all: true }).then(({ data }) => {
        availableObjects.value = data;
        // Verificar si todos los elementos están seleccionados
        updateSelectAllState();  // Actualizar el estado del 'selectAll'
      });

      window.$traccar.getDrivers(selection.value).then(({ data }) => {
        selectedObjects.value = data;
      });
    }

    show.value = true;
  };

  provide("showObjects", showObjects);

  defineExpose({
    showObjects
  });
</script>

<style>
.itm {
  border-bottom: silver 1px dotted;
}

.itm div {
  border-right: silver 1px dotted;
}

.tr1 {
  background: #f3f3f3;
}

.tr2 {
  background: #f8f8f8;
}

.selected {
  background: rgba(5, 167, 227, 0.05) !important;
}

.itm div:last-child {
  border-right: none;
}

.el-select.el-select--large {
  width: 100%;
}

.el-dialog__header,
.el-dialog__body,
.el-dialog__footer {
  padding: 0px !important;
}

.el-dialog__footer {
  margin-top: 0px;
}

.el-tabs__nav-wrap {
  padding-left: 20px;
  padding-right: 20px;
}

.el-tabs__content {
  padding-left: 20px;
  padding-right: 20px;
}

.danger {
  --el-button-text-color: var(--el-color-danger) !important;
  --el-button-bg-color: #fef0f0 !important;
  --el-button-border-color: #fbc4c4 !important;
  --el-button-hover-text-color: var(--el-color-white) !important;
  --el-button-hover-bg-color: var(--el-color-danger) !important;
  --el-button-hover-border-color: var(--el-color-danger) !important;
  --el-button-active-text-color: var(--el-color-white) !important;
  --el-button-active-border-color: var(--el-color-danger) !important;
}

.tblItem:after {
  content: ", ";
}

.tblItem:last-child:after {
  content: "";
}
</style>